import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { refineHTMLContent } from '../utils/browser';

const Title = styled.h1`
  margin: 0 1rem 0 0;
  font-size: 2rem;
  font-weight: 500;
  color: #343434;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 2rem 0;
`;

function BlogPage({ data }) {
  const { trangBlog: blog } = data;

  return (
    <Layout>
      <SEO title={blog.title} />

      <Header>
        <Title>{blog.title}</Title>
      </Header>
      <div dangerouslySetInnerHTML={{ __html: refineHTMLContent(blog.content.extended) }} />
    </Layout>
  );
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    trangBlog: PropTypes.shape({
      content: PropTypes.shape({
        brief: PropTypes.string,
        extended: PropTypes.string,
      }),
      title: PropTypes.string,
    }),
  }).isRequired,
};

export default BlogPage;

export const pageQuery = graphql`
  query($slug: String!) {
    trangBlog(slug: { eq: $slug }) {
      id
      slug
      title
      state
      updatedAt
      updatedBy {
        email
      }
      publishedDate
      createdAt
      createdBy {
        email
      }
      content {
        brief
        extended
      }
    }
  }
`;
